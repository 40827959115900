import LoginWithSSOButton from 'features/okta/components/LoginWithSSOButton';
import OktaLoginForm from 'features/okta/components/LoginWithOktaForm';

export const enum LoginView {
 OKTA_WIDGET = 'OKTA_WIDGET',
 OKTA_REDIRECT = 'OKTA_REDIRECT',
}

export const Views = {
 OKTA_WIDGET: () => <OktaLoginForm />,
 OKTA_REDIRECT: () => <LoginWithSSOButton />,
};
