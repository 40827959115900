import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Page } from 'shared/components/analytics/page.component';
import { MigrationPhase } from 'shared/components/banner-post-login/use-okta-migration-banner-config';

import './login.component.scss';
import { LoginView, Views } from '@pages/login/Views';
import AuthSwitcher from '@features/security/components/AuthSwitcher';
import { isOkta } from 'utils/auth';

export interface ILoginPage {
 oktaUIEnabled?: boolean;
}

export const LoginPage = ({ oktaUIEnabled = false }: ILoginPage) => {
 const [hasOktaCreds, setHasOktaCreds] = useState(false);
 const { isOktaEnabled, oktaMigrationPhase } = useFlags();
 const queryParams = new URLSearchParams(window.location.search);

 const isOktaLoginOnly = oktaMigrationPhase === MigrationPhase.OKTA_LOGIN_ONLY;

 isOkta()
  .then(result => setHasOktaCreds(result))
  .catch(err => setHasOktaCreds(false));

 const defaultView = ((isOktaEnabled || oktaUIEnabled) && hasOktaCreds && 'OKTA_WIDGET') || 'OKTA_WIDGET';

 // if migration phase is okta login only then view is okta_widget, else get the view from the querystring or assign the default
 const view = isOktaLoginOnly ? 'OKTA_WIDGET' : ((queryParams.get('view') || defaultView).toUpperCase() as LoginView);

 return (
  <Page name="Login">
   {Views[view]()}
   {!isOktaLoginOnly && <AuthSwitcher />}
  </Page>
 );
};

export const Login = LoginPage;
